import * as React from 'react';
import logo from './img/desenhando-final.png';

export const Home = () => {
  return (
    <div className="patricia-bg w-screen h-screen flex justify-center">
      <div className="h-full bg-gray-50 md:container md:mx-auto">
        <div className="pt-10">
          <div className="w-full">
            <img className="mx-auto" width="200px" src={logo} alt="logo" />
          </div>
          <div className="text-center">
            <p>&nbsp;</p>
            <p className="text-center">Dra Patrícia Rocha Machado</p>
            <p>Oftalmologista</p>
            <p>CRM 17954 RQE 9771</p>
            <p>&nbsp;</p>

            <p>
              <a href="https://g.co/kgs/sgmQYX5">
                HOF - Hospital de Olhos de Florianópolis - Unidade Campeche
              </a>
              <br />
              Av. Pequeno Príncipe, 1482 - sala 04 - Campeche - Florianópolis -
              SC
              <br />
              Tel: <a href="tel:+554832120101">(48) 3212-0101</a>
              <br />
            </p>
            <p>&nbsp;</p>
            <p>
              <a href="https://goo.gl/maps/fWUYTbzyu8ZmkLcB7">
                HOF - Hospital de Olhos de Florianópolis - Unidade Centro
              </a>
              <br />
              Servidão Missão Joven, 38 - Centro - Florianópolis - SC
              <br />
              Tel: <a href="tel:+554832120101">(48) 3212-0101</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
