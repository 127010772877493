import * as React from 'react';
import {
  useFirebaseApp,
  useInitPerformance,
} from 'reactfire';
import { Analytics } from './Analytics';
import { Home } from './Home';

// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
//import { connectAuthEmulator, getAuth } from 'firebase/auth';
//import { connectFunctionsEmulator } from '@firebase/functions';
//import { getFunctions } from '@firebase/functions';
//import { connectStorageEmulator, getStorage } from '@firebase/storage';
//import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

export const App = () => {
  const firebaseApp = useFirebaseApp();
  //const auth = getAuth(firebaseApp);
  //const functions = getFunctions(firebaseApp);
  //const storage = getStorage(firebaseApp);
  //const firestore = getFirestore(firebaseApp);

  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.EMULATORS === 'true'
  ) {
    //const origin = window.location.hostname;
    //connectAuthEmulator(auth, `http://${origin}:9099`);
    //connectFunctionsEmulator(functions, origin, 5001);
    //connectStorageEmulator(storage, origin, 9199);
    //connectFirestoreEmulator(firestore, origin, 8080);
  }

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  return (
    <>
      <Home />
      <Analytics />
    </>
  );
};
