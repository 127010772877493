import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './App';
import './styles.pcss';
import { FirebaseAppProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: "AIzaSyB0SIy3ju3KIw7FNJLbGAi0xMfWb_vFckQ",
  authDomain: "drapatriciamachado-50a56.firebaseapp.com",
  projectId: "drapatriciamachado-50a56",
  storageBucket: "drapatriciamachado-50a56.appspot.com",
  messagingSenderId: "149440311060",
  appId: "1:149440311060:web:7110b703e2176af3a05c01",
  measurementId: "G-KPEENTQRXW"
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find root element');
}

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  rootElement
);
